import React, {Component} from "react";
import {connect} from "react-redux"
import {withRouter} from "react-router-dom";

@connect((store) => {
    return {
        farm: store.location.farm
    };
})
export class Home extends Component {

    constructor(props) {
        super(props);
        const {farm} = this.props;
        this.props.history.push(`/${farm}/farm`);
    }

    render() {
        // TODO dodac kafelki w przypadku braku fermy
        // TODO: zrobic cos innego niz przekierowanie na ferme na glownym widoku
        return (
            <div/>
        )
    }
}

export default withRouter(Home);


