import React from "react";
import Card from "../components/basics/card/Card";
import LabeledInput from "../components/basics/input/labeled-input/LabeledInput";
import Button from "../components/basics/button/Button";
import Switch from "../components/basics/switch/Switch";
import InfoBox from "../components/basics/info-box/InfoBox";
import Checkbox from "../components/basics/checkbox/Checkbox";
import Slider from "../components/basics/slider/Slider";
import Highlight from "../components/basics/highlight/Highlight";
import MonoChart from "../components/basics/monochart/MonoChart";
import BridgeInterfaceSelector from "../components/bridge-interface-selector/BridgeInterfaceSelector";
import ItemPicker from "../components/basics/item-picker/ItemPicker";
import SS0 from "../resources/images/standings-sorting/0.svg"
import SS1 from "../resources/images/standings-sorting/1.svg"
import SS2 from "../resources/images/standings-sorting/2.svg"
import SS3 from "../resources/images/standings-sorting/3.svg"
import SS4 from "../resources/images/standings-sorting/4.svg"
import SS5 from "../resources/images/standings-sorting/5.svg"
import SS6 from "../resources/images/standings-sorting/6.svg"
import SS7 from "../resources/images/standings-sorting/7.svg"
import RolePicker from "../components/users/role-picker/RolePicker";
import ListItem from "../components/basics/list/list-item/ListItem";
import List from "../components/basics/list/List";
import Chart from "../components/basics/chart/Chart";
import moment from "moment";
import AnalogClock from "../components/analog-clock/AnalogClock";
import VirtualizedList from "../components/basics/virtualized-list/VirtualizedList";
import TableGrid from "../components/basics/table-grid/TableGrid";

export const farmDevices = {
    "devices": [
        {
            "DtaModTime": 1550091878153,
            "Ver": "1.01",
            "GatewayID": "GW_DEMO_19_02_13_220252808",
            "Settings": {
                "SSHPublicKey": "none",
                "SSHPrivateKey": "none",
                "CertID": "7db5ce2ded8cf306b110445893f4c14b027cca2c35ac33e0cf5057e8186803d8"
            },
            "VerHard": "0",
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "GW",
            "FarmID": "WesstronDemoFarm",
            "VerSoft": "0",
            "DevID": "GW_DEMO_19_02_13_220252808",
            "PlcmntID": "unbinded",
            "Name": "?",
            "$loki": 1
        },
        {
            "Address": 2,
            "DtaModTime": 1550126830299,
            "Settings": {},
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "CL",
            "FarmID": "WesstronDemoFarm",
            "Interface": 4,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "DtaCrtTime": 1550126830299,
            "DevID": "GLRXWUUibL6h1ryjWAE2",
            "Name": "Sterownik klimatu",
            "$loki": 2,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 10,
            "DtaModTime": 1550129563862,
            "Settings": {},
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "SC",
            "FarmID": "WesstronDemoFarm",
            "Interface": 4,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "DtaCrtTime": 1550128489631,
            "DevID": "V7A2jlnaA7A8Gb5Y2VtS",
            "Siloses": [
                {
                    "Active": true,
                    "Capacity": 10000,
                    "Adr": 0,
                    "Name": "Silos 1"
                },
                {
                    "Active": true,
                    "Capacity": 10000,
                    "Adr": 1,
                    "Name": "Silos 2"
                },
                {
                    "Adr": 2
                },
                {
                    "Adr": 3
                },
                {
                    "Adr": 4
                },
                {
                    "Adr": 5
                },
                {
                    "Adr": 6
                },
                {
                    "Adr": 7
                }
            ],
            "Name": "Sterownik wag",
            "$loki": 3,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 1,
            "DtaModTime": 1550132294623,
            "Interfaces": [
                null,
                "CAN",
                "CAN",
                "WST",
                "WST",
                "NRF",
                "NRF",
                "NRF",
                null,
                null,
                "LCD",
                null,
                null,
                null
            ],
            "VerHard": "0.1",
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "BR",
            "FarmID": "WesstronDemoFarm",
            "ParentID": "GW_DEMO_19_02_13_220252808",
            "VerSoft": "0.1",
            "DtaCrtTime": 1550126726195,
            "DevID": "mUf2v0qlSGAw-Dsl329E",
            "Name": "Bridge",
            "$loki": 4,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 8961,
            "DtaModTime": 1550134181564,
            "VerHard": "0.1",
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "DI_NRF",
            "FarmID": "WesstronDemoFarm",
            "Interface": 5,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "VerSoft": "0.1",
            "DtaCrtTime": 1550134181564,
            "DevID": "mUf2v0qlSGAw-Dsl329E_L_0x2301",
            "Name": "Dispenser Nrf",
            "$loki": 5,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 9473,
            "DtaModTime": 1550134181622,
            "VerHard": "0.1",
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "DI_NRF",
            "FarmID": "WesstronDemoFarm",
            "Interface": 5,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "VerSoft": "0.1",
            "DtaCrtTime": 1550134181622,
            "DevID": "mUf2v0qlSGAw-Dsl329E_L_0x2501",
            "Name": "Dispenser Nrf",
            "$loki": 6,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 4865,
            "DtaModTime": 1550134183416,
            "VerHard": "0.1",
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "DI_NRF",
            "FarmID": "WesstronDemoFarm",
            "Interface": 7,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "VerSoft": "0.1",
            "DtaCrtTime": 1550134183416,
            "DevID": "mUf2v0qlSGAw-Dsl329E_C_0x1301",
            "Name": "Dispenser Nrf",
            "$loki": 7,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 5889,
            "DtaModTime": 1550134183419,
            "VerHard": "0.1",
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "DI_NRF",
            "FarmID": "WesstronDemoFarm",
            "Interface": 7,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "VerSoft": "0.1",
            "DtaCrtTime": 1550134183419,
            "DevID": "mUf2v0qlSGAw-Dsl329E_C_0x1701",
            "Name": "Dispenser Nrf",
            "$loki": 8,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        },
        {
            "Address": 1,
            "DtaModTime": 1550231510053,
            "Settings": {
                "Insertions": [
                    {
                        "Start": 1550185200000,
                        "PigsInserted": 400
                    }
                ]
            },
            "CliIDFaID_C": "WesstronDemo+WesstronDemoFarm",
            "DevType": "CA",
            "FarmID": "WesstronDemoFarm",
            "Interface": 3,
            "ParentID": "mUf2v0qlSGAw-Dsl329E",
            "DtaCrtTime": 1550126814760,
            "DevID": "prGiB2NDvJ-3jF4qNXSc",
            "Name": "Klatka separacyjna",
            "$loki": 9,
            "GatewayID": "GW_DEMO_19_02_13_220252808"
        }
    ],
    "fetching": false,
    "fetched": true,
    "connected": false,
    "connectedDevices": {},
    "pingedDevices": {},
    "devicesSearch": false,
    "devicesFound": [],
    "startedAddressing": false,
    "updateSend": {}
};


const dataCage = [
    {
        "name": "2019-04-30T00:00:00.000Z",
        "all": 114,
        "left": 0,
        "right": 0,
        "middle": 0
    },
    {
        "name": "2019-05-06T00:00:00.000Z",
        "all": 123.2,
        "left": 0,
        "right": 0,
        "middle": 0
    },
    {
        "name": "2019-05-07T00:00:00.000Z",
        "all": 123.2,
        "left": 0,
        "right": 0,
        "middle": 0
    },
    {
        "name": "2019-05-09T00:00:00.000Z",
        "all": 123.2,
        "left": 0,
        "right": 0,
        "middle": 0
    },
    {
        "name": "2019-05-10T00:00:00.000Z",
        "all": 123.2,
        "left": 0,
        "right": 0,
        "middle": 0
    },
    {
        "name": "2019-05-13T00:00:00.000Z",
        "all": 10,
        "left": 0,
        "right": 0,
        "middle": 0
    },
    {
        "name": "2019-05-14T00:00:00.000Z",
        "all": 10,
        "left": 0,
        "right": 0,
        "middle": 0
    }
];
export default class TestView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            itemPickerValue: 1,
            rolePickerValue: 0
        }
    }

    hehe = ({text, style}) => <div style={style}>{text}</div>

    render() {
        const colors = ["", "primary", "secondary", "success", "info", "warning", "error"];
        const buttonTypes = [null, "round", "bordered", "text"];
        const data = new Array(50).fill(1).map((o, i) => {
            const tmp = {
                name1: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name2: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name3: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name4: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name5: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name6: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name7: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest",
                name8: "TestTestTestTestTestTestTestTestTestTestTestTestTestTest"
            }
            return tmp;
        });
        const headers = [
            {
                name: "Name 1",
                field: "name1"
            }, {
                name: "Name 2",
                field: "name2"
            }, {
                name: "Name 3",
                field: "name3"
            }, {
                name: "Name 4",
                field: "name4"
            }, {
                name: "Name 5",
                field: "name5"
            }, {
                name: "Name 6",
                field: "name6"
            }, {
                name: "Name 7",
                field: "name7"
            }, {
                name: "Name 8",
                field: "name8"
            },
        ]
        if(1)return (
            <div>
                <TableGrid resizable data={data} headers={headers}/>
            </div>
        )
        if (1) return (
            <VirtualizedList component={this.hehe} data={new Array(25).fill({text: "XDD"})}/>
        )
        return (
            <>
                <Card>
                    <Slider value={this.state.slider || 0} onChange={value => this.setState({slider: value})}/>
                </Card>
                <Card>
                    <AnalogClock time={+new Date()}/>
                    <LabeledInput label="Text" disabled={true} type="text"/>
                    <LabeledInput label="xDDDD" type="text"/>
                    <LabeledInput label="xDDDD" type="text" required/>
                    <LabeledInput label="xDDDD" type="text"/>
                    <LabeledInput disabled={true} label="xDDDD" type="text"/>
                </Card>
                <Card>
                    {
                        buttonTypes.map(type => (
                            <div>
                                {
                                    colors.map((color, i) => (
                                        <Button icon={type === "round" ?
                                            <i className={"fas fa-check"}/> : (i % 2 === 0 ? null :
                                                <i className={"fas fa-check"}/>)} buttonStyle={type}
                                                buttonColor={color}>{`${type}-${color}`}</Button>
                                    ))
                                }
                            </div>

                        ))
                    }
                </Card>
                <Card>
                    <Switch label={"Włącz nagrzewnice"}/>
                    <Checkbox label={"Włącz nagrzewnice"}/>
                    <InfoBox boxColor={"info"}>Hello my old friend</InfoBox>
                    <InfoBox boxColor={"primary"}>Hello my old friend</InfoBox>
                    <InfoBox boxColor={"error"}>Hello my old friend</InfoBox>
                    <InfoBox boxColor={"warning"}>Hello my old friend</InfoBox>
                </Card>
                <Card>
                    <Slider min={0} max={100}/>
                </Card>
                <Card>
                    <h1>Fetutura</h1>
                    <h2>Section Title</h2>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at egestas leo, at consequat lorem.
                    Etiam at ligula et nisl maximus commodo.
                    <h3>Subsection Title</h3>
                    Aliquam elit arcu, iaculis vitae hendrerit sed, rutrum quis magna. Suspendisse iaculis sit amet enim
                    quis interdum. Quisque fringilla et mauris at sollicitudin. Aenean dictum volutpat elit id varius.
                    Fusce nec pellentesque arcu, ac dictum ex.
                    <h4>Subsection Title</h4>
                    Sed sagittis ipsum eu purus condimentum accumsan. Pellentesque volutpat porttitor est, sit amet
                    tincidunt risus vehicula porttitor. Morbi condimentum dapibus fringilla.
                    <p>paragaaapsdpads</p>dadasad
                    <b>dasadsasd</b>dadasdada<strong>dsaasddasdasdas</strong>asasdasdads
                    <i>dsasddsads</i>asddasdasdasads
                    dads<a>das</a>asddas<a href={"sdasdds"}>dsaasddas</a>daasddas
                </Card>
                <Card>
                    <ItemPicker value={this.state.itemPickerValue}
                                onChange={(value) => this.setState({itemPickerValue: value})} showLabels={true}
                                options={[{value: 0, svgPath: SS0, label: 'wauwauw'}, {
                                    value: 1,
                                    svgPath: SS1
                                }, {value: 2, svgPath: SS2}, {value: 3, svgPath: SS3}, {
                                    value: 4,
                                    svgPath: SS4
                                }, {value: 5, svgPath: SS5}, {value: 6, svgPath: SS6}, {value: 7, svgPath: SS7}]}/>
                </Card>
                <Card>
                    {
                        colors.map((color, i) => (
                            <React.Fragment>
                                Highlight <Highlight highlightColor={color}>this and <Highlight
                                highlightColor={"secondary"}>that</Highlight></Highlight>
                            </React.Fragment>
                        ))
                    }
                </Card>
                <Card>
                    <div style={{height: 300}}>
                    </div>
                    {
                        colors.map((color, i) => (
                            <React.Fragment>
                                <div style={{height: 300}}>
                                    <MonoChart background={true} color={color} dataKey={"value"}
                                               data={[{value: 1}, {value: 1}, {value: 1}, {value: 1}, {value: 1}, {value: 2}]}/>
                                </div>
                                <div style={{height: 300}}>
                                    <MonoChart color={color} dataKey={"value"}
                                               data={[{value: 1}, {value: 1}, {value: 1}, {value: 1}, {value: 1}, {value: 2}]}/>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </Card>
                <Card>
                    <BridgeInterfaceSelector/>
                </Card>
                <Card>
                    <RolePicker/>
                </Card>
                <div>
                    <List>
                        {new Array(5).fill(1).map(o => (
                            <ListItem>{o}</ListItem>
                        ))}
                    </List>
                </div>
                <div>
                    <div style={{width: 1000, height: 500}}>
                        <Chart data={dataCage} dataDef={[{
                            color: "orange",
                            dataKey: "all",
                            name: "Średnia waga"
                        }]} Xaxis={{
                            name: "Dzień",
                            dataKey: "name",
                            formatter: (stringDate) => moment(stringDate).format("DD.MM.YY")
                        }}
                               Yaxis={{
                                   name: "Ilość przejść"
                               }}
                        />
                    </div>
                </div>
            </>
        )
    }

}
