import React, {Component} from "react";
import {connect} from "react-redux"
import {getUserAttributes, getUserSuccess} from "../actions/userActions";
import {
    Authenticator,
    ConfirmSignUp,
    ForgotPassword,
    Greetings,
    Loading,
    RequireNewPassword,
    SignIn,
    VerifyContact
} from "aws-amplify-react";
import CustomSignIn from "../components/login/CustomSignIn";
import {getCurrentUser} from "../libs/awsLib";
import CustomForgotPassword from "../components/login/CustomForgotPassword";
import CustomConfirmSignUp from "../components/login/CustomConfirmSignUp";
import CustomNewPassword from "../components/login/CustomNewPassword";
import CustomVerifyContact from "../components/login/CustomVerifyContact";
import sha from "crypto-js/sha256";
import NewIOT from "../IOT/NewIOT";
import {Hub} from "@aws-amplify/core"
import {setGlobalLoading} from "../actions/viewActions";
import {AWSLoginMessageMap} from "../utils/TranslationUtils";
import {withTranslation} from "react-i18next";
import LoadingComponent from "../components/loading/LoadingComponent";
import queryString from "query-string";
import i18next from "i18next";

@connect((store) => {
    return {
        user: store.user.user,
        isLoading: store.user.fetching,
        form: store.form,
        userStore: store.user,
    };
})
export class Login extends Component {

    state = {
        error: "",
        user: null,
        loading: false,
        username: null,
        passwd: null
    };

    constructor(props) {
        super(props);
        console.error("login constructor");
        // sprawdzenie czy sa zapisane dane do logowania i czy jest polaczenie z netem
        let key = null;
        for (let i = 0; i < localStorage.length; i++) {
            let k = localStorage.key(i)
            if (k.includes("localRefreshToken")) {
                key = k;
                break;
            }
        }
        console.log(key);
        if (key) {
            let login = key.replace(".localRefreshToken", "");
            console.log(login);
            this.state.loading = true;
            fetch("https://icanhazip.com/").then(() => {
                // jezeli jest polaczenie z netem to usun dane logowania offline i pokazd inputy
                this.setState({
                    loading: false
                });
                localStorage.removeItem(`${login}.token`);
                localStorage.removeItem(`${login}.createTokenTime`);
                localStorage.removeItem(`${login}.localRefreshToken`);
                localStorage.removeItem(`${login}.createRefreshTokenTime`);
            }).catch(e => {
                // jezeli nie ma polaczenia to sprobuj zalogowac
                let refreshTokenExpirationTime = localStorage.getItem(`${login}.createRefreshTokenTime`);
                if (refreshTokenExpirationTime > new Date().getTime()) {
                    let refreshToken = localStorage.getItem(`${login}.localRefreshToken`);
                    this.offlineLogin(login, null, refreshToken);
                }
            })
        }

        Hub.listen("auth", this.hubListener);
    }

    messageMap = (message) => {
        switch(message){
            case "Username cannot be empty": // wyrzuca jak nie ma wpisanego hasła
               this.setState({
                   error: i18next.t("errors.noEmptyLogin")
               });
               break;
            case "Confirmation code cannot be empty": // kod weryfikacyjny jest pusty
                this.setState({
                    error: i18next.t("errors.noEmptyVerificationCode")
                });
            break;
            case "Password cannot be empty": // hasło jest puste
                this.setState({
                    error: i18next.t("errors.noEmptyPassword")
                });
            break;
            case "Password does not conform to policy: Password not long enough": // hasło jest puste
                this.setState({
                    error: i18next.t("errors.passLognerThan")
                });
                break;
            case "Password does not conform to policy: Password must have uppercase characters": // hasło jest puste
                this.setState({
                    error: i18next.t("errors.passMinOneBig")
                });
                break;
            case "Password attempts exceeded": // przekroczono liczbe prob
                this.setState({
                    error: i18next.t("errors.passwordAttemptsExceeded")
                });
                break;
            default:
                return AWSLoginMessageMap(message)
        }

    };

    async componentDidMount() {
        let user = await getCurrentUser();
        const {location} = this.props;
        let username = null;
        let passwd = null;
        if (location.pathname === "/login") {
            let queryParams = queryString.parse(location.search);
            if (!!queryParams) {
                const {userName, pwd} = queryParams;
                username = userName;
                passwd = pwd;
            }
        }
        this.setState({
            user: user || null,
            username,
            passwd
        });
    }

    clearErrors = () => {
        this.setState({
            error: ""
        })
    }

    async offlineLogin(user, pass, refreshToken) {
        this.props.dispatch(setGlobalLoading(true));
        setTimeout(() => {
            this.props.dispatch(setGlobalLoading(false));
        }, 20000);
        // usun ladowanie po 300ms w celach estetycznych
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, 300);
        await NewIOT.localAuthUser(user, pass, refreshToken,(msg) => {
            this.props.dispatch({
                type: "USER_GET_ATTRIBUTES_FULFILLED",
                payload: {
                    sub: msg.CAnsw.user.sub
                }
            });
            this.props.dispatch({
                type: "GET_USER_FULFILLED",
                payload: msg.CAnsw.user
            });
            this.props.dispatch({type: "MQTT_CONNECT_FULFILLED"})
            getUserSuccess({value: msg.CAnsw.user}, this.props.dispatch, msg.CAnsw.user.sub);
            localStorage.setItem(`${user}.token`, msg.CAnsw.token);
            localStorage.setItem(`${user}.createTokenTime`, msg.CAnsw.createTokenTime);
            localStorage.setItem(`${user}.localRefreshToken`, msg.CAnsw.refreshToken);
            localStorage.setItem(`${user}.createRefreshTokenTime`, msg.CAnsw.createRefreshTokenTime);
        }, (error, msg) => {
            if (error === "task_timeout") {
                this.setState({
                    error: this.messageMap("No connection to server")
                })
            } else if (error.code === 1) {
                this.setState({
                    error: this.messageMap("Incorrect username or password.")
                })
            } else if (error.code === 3) {
                this.setState({
                    error: this.messageMap("Session expired.")
                })
            } else {
                this.setState({
                    error: "???"
                });
                console.error(error, msg);
            }
            this.props.dispatch(setGlobalLoading(false));
            NewIOT.endConnection();
            localStorage.removeItem(`${user}.token`);
            localStorage.removeItem(`${user}.createTokenTime`);
            localStorage.removeItem(`${user}.localRefreshToken`);
            localStorage.removeItem(`${user}.createRefreshTokenTime`);
        });
    }

    hubListener = async data => {
        this.setState({
            error: ""
        });
        if (data.payload.event === "signIn_failure" && data.payload.data.code === "NetworkError") {
            let userInput = document.getElementById("username");
            let user = userInput.value;
            let passwordInput = document.getElementById("password");
            let password = passwordInput.value;
            let pass = sha(password).toString();
            await this.offlineLogin(user, pass);
        } else if (data.payload.event === 'configured') {
            this.setState({
                error: this.messageMap(data.payload.message)
            })
        } else {
            if (data.payload.data) {
                this.setState({
                    error: this.messageMap(data.payload.data.message)
                })
            }
        }
    }

    componentWillUnmount() {
        Hub.remove("auth", this.hubListener);
    }

    onStateChange = async (authState) => {
        const {t} = this.props;
        this.setState({
            error: ""
        });
        switch (authState) {
            case "signedIn":
                this.props.dispatch(getUserAttributes(true));
                break;
            case "confirmSignUp":
                this.setState({
                    error: t("errors.UserNotConfirmed")
                });
                break;
            default:
                break;
        }
    };


    render() {
        const {user, error, loading, username, passwd} = this.state;
        let theme = {
            toast: {
                display: "none"
            }
        };
        return (
            <>
                <LoadingComponent isLoading={loading}/>
                <Authenticator
                    hide={[SignIn, Greetings, ForgotPassword, ConfirmSignUp, RequireNewPassword, VerifyContact, Loading]}
                    onStateChange={this.onStateChange} authData={user} errorMessage={this.messageMap}
                    theme={theme} ref={this.authenticator}>
                    <CustomSignIn clear={this.clearErrors} log={username} pass={passwd} error={error}/>
                    <CustomForgotPassword error={error}/>
                    <CustomConfirmSignUp error={error}/>
                    <CustomNewPassword error={error}/>
                    <CustomVerifyContact error={error}/>
                </Authenticator>
            </>
        )
    }
}

Login = withTranslation()(Login);
export default Login;
