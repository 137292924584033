import React from "react";
import "./_not-found-component.scss";
import {Col, Row} from "react-bootstrap";
import Card from "../components/basics/card/Card";
import Button from "../components/basics/button/Button";
import {redirect} from "../actions/historyActions";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

@connect()
class NotFound extends React.Component {

    onBack = () => {
        const {dispatch} = this.props;
        dispatch(redirect("/"));
    };

    render() {
        const {t} = this.props;
        return (
            <div className={"not-found-component"}>
                <Row bsPrefix={"row h-100 justify-content-center align-items-center w-100 mx-auto"}>
                    <Col lg={6}>
                        <Card>
                            <h4>{t("pageNotFound.title") + " :("}</h4>
                            <p>
                                {t("pageNotFound.message")}{' '}
                                <Button type={"button"} onClick={this.onBack}>
                                    {t("here")}
                                </Button>
                            </p>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}


export default withTranslation()(NotFound);
