import React, {Component} from "react";
import {connect} from "react-redux"
import db from "../database/lokiDB"
import {getAggregatedData} from "../actions/aggregatedActions";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {generateDeviceTree} from "../utils/DevicesUtils";
import hash from "object-hash"
import {hashCRC} from "../utils/CRCUtils";
import {DevType} from "../constans/devices";
import {getEvents} from "../actions/eventsActions";
import animalsDB from "../database/animalsDB"
import {myID} from "../libs/generateID";
import Event from "../beans/Event"
import {EventTypes} from "../constans/eventTypes";
import withRoles from "../components/withRoles";
import {Roles} from "../constans/roles";
import NewIOT from "../IOT/NewIOT";

@connect((store) => {
    return {
        farms: store.farms.farms,
        devices: store.farmDevices.devices,
        user: store.user.user
    };
})
export class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {thingName: '', thingCommand: '', animal: undefined};

        this.handleChangeThing = this.handleChangeThing.bind(this);
        this.handleChangeCommand = this.handleChangeCommand.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleGetHelp = this.handleGetHelp.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    handleChangeThing(event) {
        this.setState({thingName: event.target.value});
    }

    handleChangeCommand(event) {
        this.setState({thingCommand: event.target.value});
    }

    onClick() {
        let count = 5;
        this.props.devices.filter(dev => +dev.DevType === +DevType.FEEDER).map((dev, index) => {
            if (index < count) {
                // subscribeAndGetSlimGridData(dev);
            }
        })
    }

    handleSubmit(event) {
        //submit
        // IOT.sendConsoleServiceMessage(this.state.thingName,this.state.thingCommand);
        //alert("Command "+this.state.thingCommand+" sent");
    }

    handleGetHelp(event) {
        console.log("HELP!");
    }

    async componentDidMount() {
        // IOT.subsByTopicName("test/frontend/output");

    }


    async handleDeleteDatabase() {
        await db.deleteDatabase().then(r => db.loadDatabase());


        db.getDatabaseList()
    }

    getAllEvents() {
        this.props.farms.map(farm => {
            this.props.dispatch(getEvents(farm.FarmID))
        })
    }

    handleGetCageData() {
        this.props.dispatch(getAggregatedData("cageTest03"))
    }

    cTrans() {
        //console.log("test");
        // var data = {
        //     token: "12345",
        //     plan: "plan1"
        // };
        // var res = invokeApig({
        //         path: "transactions/subscription/1",
        //         method: "POST",
        //         queryParams: {token: "12345", plan: "plan1"}
        //     }
        // );
        // res = invokeApig({
        //         path: "transactions/transaction/1",
        //         method: "GET",
        //         queryParams: {nonceFromTheClient: "asd"}
        //     }
        // )
    }

    onCommandHistoryClick() {
        // this.props.dispatch(fetchCommands(this.props.user.ClientID));
    }

    hancleOnClickGetFeedData() {
        NewIOT.createAndSendMessageObjectToStateTopic("TestNewRole", "GW_DANB_PRIV_19_07_05_113809003", "ServiceWesstronDB-12072019", "GW_DANB_PRIV_19_07_05_113809003", "GET_FEED_STATE", {DeviceIDs: ["4AmHuHEjJobDmMArzxX4"]});
    }

    curve2(curve) {
        let curve2 = [];
        curve.map(c => {
            let t = {};
            t.dailyDose = c.dailyDose;
            t.scheduleNumber = c.scheduleNumber;
            t.foodType = c.foodType;
        });
        return curve2;

    }

    getErrors() {
        // this.props.farms.map(farm => {
        //     IOT.sendMessageToPing({
        //         status: 'refreshErrorData'
        //     }, farm.FarmID)
        // })
    }

    sendErrorsReport() {
        // this.props.farms.map(farm => {
        //     IOT.sendMessageToPing({
        //         status: 'sendErrorData'
        //     }, farm.FarmID)
        // })
    }

    getAnimal = async () => {
        // let animals = await animalsDB.getAllAnimalsDeadOrAlive();
        let animals = await animalsDB.getAllAnimals(undefined, undefined, true);
        console.log(animals);
        this.setState({
            animal: animals[0]
        })
    };

    changeAnimalWithoutCloning = () => {
        let animal = this.state.animal;
        if (animal) {
            animal.AnmNo1 = (Math.random() * 100 + 1) + "";
            this.setState({
                animal
            })
        }
    };

    onGetAllAnimalsClick = () => {
        animalsDB.getAllAnimals("WesstronNewiot1")
    };

    clone = () => {
        let animal = animalsDB._findOneAnimal({});
        console.log(animal);
        let anm = animal.clone();
        console.log(anm.equals(animal));
        anm.setDthTime();
        if (animal.equals(anm)) console.log("xD");
        else console.log("xD2");
        if (anm.isDead()) console.log("xD");
        else console.log("xD2");
        console.log(anm);
        anm.removeDthTime();
        console.log(anm);
    };

    createEvent = () => {
        let ev = Event.createEvent("qweqewqew", EventTypes.NO_PREGNANCY, 1, +new Date(), "qweqwqwe", "ASDasdasd", {Reason: "qweqew"});
        console.log(ev);
    };

    render() {
        let curve = [
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 100
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 200
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 300
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 400
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 500
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 600
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 700
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 800
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 900
            },
            {
                "foodType": 0,
                "scheduleNumber": 1,
                "dailyDose": 1000
            }
        ];

        let curveString = "[{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":100},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":200},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":300},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":400},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":500},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":600},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":700},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":800},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":900},{\"foodType\":0,\"scheduleNumber\":1,\"dailyDose\":1000}]";
        let crash = () => this.props.crash.me.please.xd;
        return (

            <div>
                <div className="main-map">
                    <button onClick={this.handleDeleteDatabase.bind(this)}>DROP DB LOKI JS</button>
                    {/*Pobieranie wszystkich eventów dl a farm*/}
                    <button onClick={() => this.getAllEvents()}>GET ALL EVENTS FOR ALL FARMS</button>
                    <button onClick={() => this.props.dispatch(getAggregatedData("CageTest02-biuro"))}>GET DATA CAGE
                    </button>
                    <button onClick={() => this.cTrans()}>GET DATA CAGE</button>
                </div>
                {/*konsola mqtt*/}

                {/*koniec konsoli mqtt*/}
                {/*cloudsearch*/}
                {/*cloudsearch*/}
                <button onClick={() => this.onCommandHistoryClick()}>Pobierz command history</button>
                <button onClick={this.onClick}>WYSLIJ WIADOMOSC DO 5 STEROWNIKOW</button>
                <p>Hash from ciesiu: 5fa769ee6426ef1267b28680575c5c8b9bd81f1d</p>
                <p>Hash Ciesiu String: {hash(curveString)}</p>
                <p>Hash default: {hash(curve)}</p>
                <p>Hash default dif order: {hash(this.curve2(curve))}</p>
                <p>Hash toString: {hash(curve.toString())}</p>
                <p>Hash Stringify: {hashCRC(JSON.stringify(curve))}</p>
                <p>Hash Stringify remove whitespace: {hash(JSON.stringify(curve).replace(/\s/g, ''))}</p>

                <button onClick={() => this.getErrors()}>Pobierz błędy z urządzeń</button>
                <button onClick={() => this.sendErrorsReport()}>Wyślij raport błędów</button>
                <button
                    onClick={() => console.log(generateDeviceTree(this.props.devices, this.props.devices.filter(dev => dev.DevType === DevType.GATEWAY)[0]))}>Generate
                    dev tree
                </button>

                <div>
                    <h4>LokiJS</h4>
                    <button onClick={this.getAnimal}>Pobierz wartość z animals</button>
                    <button onClick={this.changeAnimalWithoutCloning}>Zmień numer zwierzęcia bez klonowania</button>
                    <button>Zmień numer z klonowaniem</button>
                    <div>{this.state.animal && JSON.stringify(this.state.animal)}</div>
                </div>
                <div>
                    <button onClick={this.onGetAllAnimalsClick}>GET ALL ANIMALS</button>
                </div>
                <div>
                    <button onClick={() => this.setState({generatedID: myID()})}>Generate id</button>
                    <div>{this.state.generatedID}</div>
                </div>
                <div>
                    test klas
                    <button onClick={this.clone}>Klonowanie</button>
                    <button onClick={this.createEvent}>Utworz event</button>
                </div>
                <div>
                    <button onClick={crash}>crash</button>
                </div>
                <div>
                    <button onClick={this.hancleOnClickGetFeedData}>getFeedData</button>
                </div>
            </div>
        );
    }
}

let _Home = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(Home);
_Home = withRoles({roles: [Roles._SERVICE], showComponent: true})(_Home);

export default _Home;
